<!-- 系统设置 权限管理 账号管理 新增或编辑或数据权限 -->
<template>
  <ykc-form ref="YkcForms" :model="ruleForm" :rules="rules">
    <ykc-form-item label="台区名称" prop="name">
      <ykc-input v-model="ruleForm.name" maxlength="30" placeholder="请输入台区名称"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="额定容量" prop="ratedCapacity">
      <ykc-input
        v-model="ruleForm.ratedCapacity"
        maxlength="30"
        placeholder="请输入额定容量"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="台区城市" prop="addressId">
      <ykc-cascader
        :isObject="true"
        :options="addressData"
        v-model="ruleForm.addressId"
        @change="changeDistrict"></ykc-cascader>
    </ykc-form-item>

    <ykc-form-item label="选择电站" prop="stationIds">
      <ykc-dropdown
        :multiple="true"
        :clearable="true"
        :isMultiLine="true"
        :multipleLimit="20"
        :data="stationIdData"
        v-model="ruleForm.stationIds"
        @change="selectChange"></ykc-dropdown>
    </ykc-form-item>

    <ykc-form-item label="选择终端" prop="pileIds">
      <ykc-tree
        ref="YkcTree"
        :data="treeData"
        :props="treeProps"
        :nodeKey="treeNodeKey"
        @check-change="handleTreeCheckChange"></ykc-tree>
    </ykc-form-item>

    <ykc-form-item label="备注" prop="remark">
      <ykc-input
        type="textarea"
        maxlength="500"
        v-model="ruleForm.remark"
        placeholder="请输入备注"></ykc-input>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import regexpObj from '@/utils/regexp';
  import { barrierPage, brandModelPage, orderlyPage, treeUtils } from '@/service/apis';

  export default {
    props: {
      id: {
        type: String,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        detail: {},
        treeData: [], // 树结构
        addressData: [], // 城市数据源
        stationIdData: [],
        treeNodeKey: 'id',
        treeProps: {
          label: 'gunName',
          children: 'gunVOList',
          disabled: 'disabled',
        },
        // 站点标签原数据
        stationLabelListData: [
          { id: '1', name: '免费WIFI' },
          { id: '2', name: '空调休息室' },
          { id: '3', name: '按摩室' },
          { id: '4', name: '便利店' },
          { id: '5', name: '洗车' },
          { id: '6', name: '饮用水' },
          { id: '7', name: '厕所' },
          { id: '8', name: '快餐' },
          { id: '9', name: '自动售货机' },
          { id: '10', name: '雨棚' },
        ],
        ruleForm: {
          name: '',
          ratedCapacity: null,
          addressId: [],
          stationIds: [],
          pileIds: [],
          remark: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入台区名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('台区名称'),
            },
          ],
          ratedCapacity: [
            { required: true, message: '请输入额定容量', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.inlineTextType.regexp,
              message: regexpObj.regexp.input.inlineTextType.errorTips.error('额定容量'),
            },
          ],
          addressId: [{ required: true, message: '请选择台区城市', trigger: 'blur' }],
          stationIds: [{ required: true, message: '请选择电站', trigger: 'blur' }],
          pileIds: [{ required: true, message: '请选择终端', trigger: 'blur' }],
        },
      };
    },
    created() {
      this.requestGetDistrict(); // 获取区域列表
      this.doFetchOrgInfos(); // 获取归属电站列表

      // 编辑回显
      if (this.id) {
        orderlyPage
          .orderPlatformDetail({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };

            // 电站地址
            this.ruleForm.addressId = [Number(res.provinceId) || null, Number(res.cityId) || null];
            this.ruleForm.stationIds = this.ruleForm.stationIds.map(String);
            this.doFindStationAndGun();
          })
          .catch(() => {});
      }
    },
    computed: {
      formData() {
        const formData = {
          name: this.ruleForm.name,
          ratedCapacity: Number(this.ruleForm.ratedCapacity),
          provinceId: this.ruleForm.addressId[0],
          cityId: this.ruleForm.addressId[1],
          stationIds: this.ruleForm.stationIds,
          pileIds: this.ruleForm.pileIds,
          remarks: this.ruleForm.remarks,
        };

        if (this.id) {
          formData.id = this.id;
        }

        return formData;
      },
    },
    methods: {
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({ level: '3' })
          .then(res => {
            this.addressData = res.districts;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * @desc 获取归属列表
       * */
      doFetchOrgInfos() {
        barrierPage
          .findStationList({})
          .then(res => {
            res.map(item => {
              return this.stationIdData.push({
                id: String(item.stationId),
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * @desc 查询电站和终端信息
       * */
      doFindStationAndGun() {
        orderlyPage
          .findStationAndGun({ stationIdList: this.ruleForm?.stationIds.map(Number) })
          .then(res => {
            this.treeData = res;
            this.treeNodeKey = 'gunId';
            const listData = res || [];
            const kayMap = { name: 'gunName', stationId: 'gunId' };
            const setEmpty = (data, keyMap) => {
              const objs = Object.keys(data).reduce((newData, key) => {
                const newKey = keyMap[key] || key;
                newData[newKey] = data[key];
                return newData;
              }, {});
              return objs;
            };

            this.treeData = listData.map(item => {
              return setEmpty(item, kayMap);
            });

            if (this.id) {
              this.$nextTick(() => {
                this.$refs.YkcTree.setCheckedKeys(this.ruleForm.pileIds, true);
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取地图需要的城市名
       */
      changeDistrict(city) {
        const arr1 = [];
        city.forEach(item => {
          arr1.push(item.name);
        });
        this.addressName = arr1.join('');
        this.ruleForm.provinceId = city[0].id;
        this.ruleForm.cityId = city[1].id;
      },
      /**
       * 获取电站
       */
      selectChange(e) {
        this.$message(`选择id为${e}的按钮`);
        this.ruleForm.stationIds = e;
        this.doFindStationAndGun(); // 获取终端列表
      },
      /**
       * 菜单树变化
       */
      handleTreeCheckChange() {
        const keys = this.$refs.YkcTree.getCheckedKeys(true);
        console.log('keys', keys);
        this.ruleForm.pileIds = keys;
      },
      /**
       * 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       */
      submitForm() {
        console.log('this.ruleForm++++', this.ruleForm);
        return new Promise(resolve => {
          this.validateForm().then(() => {
            if (!this.id) {
              orderlyPage
                .saveOrUpdate(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              console.log('编辑', this.formData);
              brandModelPage
                .saveOrUpdate(this.formData)
                .then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /**
       *
       */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.YkcForms.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
</style>
