<!--
 * @Author: wang peng
 * @Description: 有序管理列表
-->
<template>
  <scroll-layout class="fleet-match-list">
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'asset:station:orderly:add'" type="plain" @click="add">
              新增
            </ykc-button>
            <ykc-button v-rbac="'asset:station:orderly:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
      <ykc-drawer
        :title="drawerTitle"
        :show.sync="showDrawer"
        :on-close="onAddDrawerClose"
        :before-cancel="onAddDrawerCancel">
        <div class="drawer-main-body" v-if="showDrawer">
          <AddOrEdit
            :id="id"
            ref="addOrEdit"
            v-if="drawerType === 'add' || drawerType === 'edit'"></AddOrEdit>
        </div>
        <template #footer>
          <div class="drawer-button-wrap">
            <template v-for="(btn, i) in drawerButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
      </ykc-drawer>
    </div>
  </scroll-layout>
</template>
<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { treeUtils, orderlyPage } from '@/service/apis';
  import { offlineExport, code } from '@/utils';

  import AddOrEdit from './AddOrEdit.vue';

  export default {
    name: 'orderlyManagementList',
    components: {
      AddOrEdit,
    },
    data() {
      return {
        id: '',
        isEdit: false,
        showDrawer: false,
        drawerType: '',
        searchParams: {
          cityId: '',
          name: '',
          pileNumMax: null,
          pileNumMin: null,
          ratedCapacity: null,
          stationNumMax: null,
          stationNumMin: null,
        },
        // 列表表格
        tableTitle: '台区列表',
        tableData: [],
        addressData: [], // 区域数据组件使用数据源
        tableOperateButtons: [], // 列表操作
        tableColumns: [
          { label: '台区编码', prop: 'id', minWidth: '150px' },
          { label: '台区名称', prop: 'name', minWidth: '150px' },
          { label: '额定容量（KVA）', prop: 'ratedCapacity', minWidth: '150px' },
          { label: '城市', prop: 'city', minWidth: '150px' },
          { label: '包含电站数', prop: 'stationNum', minWidth: '150px' },
          { label: '包含终端数', prop: 'pileNum', minWidth: '150px' },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => {
              return true;
            },
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.showDrawer = false;
                this.requestBrandList();
                this.requestList();
              });
            },
          },
        ],
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'name',
            label: '台区名称',
            placeholder: '请输入台区名称',
          },
          {
            comName: 'YkcInput',
            key: 'ratedCapacity',
            label: '额定容量',
            placeholder: '请输入额定容量',
          },
          {
            comName: 'YkcCascader',
            key: 'cityIds',
            label: '归属城市',
            configSet: {
              label: 'name',
              value: 'id',
              children: 'subNodes',
            },
            options: this.addressData,
          },
          {
            isDouble: true,
            comName: 'YkcInput',
            label: '包含电站',
            key1: 'stationNumMin',
            key2: 'stationNumMax',
            placeholder: '请输入包含电站',
          },
          {
            isDouble: true,
            comName: 'YkcInput',
            label: '包含终端',
            key1: 'pileNumMin',
            key2: 'pileNumMax',
            placeholder: '请输入包含终端',
          },
        ];
      },
      drawerTitle() {
        let txt = '';
        if (this.drawerType === 'add') {
          txt = `有序管理新增`;
        } else if (this.drawerType === 'edit') {
          txt = `有序管理编辑`;
        }
        return txt;
      },
    },
    created() {
      this.requestGetDistrict();
      this.requestList();
      this.initData();
    },
    methods: {
      /**
       * 初始数据
       */
      initData() {
        this.tableOperateButtons = [
          {
            enabled: () => code('asset:station:orderly:edit'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                onClick={() => {
                  this.isEdit = true;
                  this.id = String(row.id);
                  this.drawerType = 'edit';
                  this.showDrawer = true;
                }}>
                编辑
              </ykc-button>
            ),
          },
        ];
      },
      /**
       * 获取区域数据
       */
      requestGetDistrict() {
        treeUtils
          .getDistrict({ level: '3' })
          .then(res => {
            const params = res.districts;
            this.addressData = params;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 有序管理分页查询
       */
      requestList() {
        const reqParams = {
          ...this.searchParams,
          ...{ size: this.pageInfo.size, current: this.pageInfo.current },
        };
        orderlyPage
          .orderPlatformPageList(reqParams)
          .then(res => {
            console.log('有序管理分页查询+++++', res);
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 新增
       */
      add() {
        this.id = '';
        this.drawerType = 'add';
        this.showDrawer = true;
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'order_platform',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        console.log('searchData++++++', searchData);
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, searchData);

        if (this.searchParams.stationNumMin && this.searchParams.stationNumMax) {
          this.searchParams.stationNumMin = Number(this.searchParams.stationNumMin);
          this.searchParams.stationNumMax = Number(this.searchParams.stationNumMax);
        }

        if (this.searchParams.pileNumMin && this.searchParams.pileNumMax) {
          this.searchParams.pileNumMin = Number(this.searchParams.pileNumMin);
          this.searchParams.pileNumMax = Number(this.searchParams.pileNumMax);
        }

        if (this.searchParams.stationNumMax < this.searchParams.stationNumMin) {
          this.$message({
            message: '包含电站数起始值不能大于终止值',
            type: 'warning',
          });
          return;
        }

        if (this.searchParams.pileNumMax < this.searchParams.pileNumMin) {
          this.$message({
            message: '包含终端数起始值不能大于终止值',
            type: 'warning',
          });
          return;
        }

        if (this.searchParams.cityIds) {
          [, this.searchParams.cityId] = this.searchParams.cityIds;
        }
        delete this.searchParams.cityIds;

        this.requestList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
        console.log('清空查询', form);
      },
      /**
       *
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       *
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 取消操作
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            // 关闭弹框
            done();
            // 关闭抽屉
            drawerDone();
          },
        });
      },
      /**
       *
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
